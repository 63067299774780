#root {
	box-sizing: border-box;
	--primary-color: rgb(245, 131, 32);
	--secondary-color: rgb(28, 124, 84);
	--tertiary-color: rgb(65, 100, 129);
	--quaternary-color: rgb(39, 70, 96);
	font-family: 'Rubik', sans-serif;
}

.home-wrapper {
	position: relative;
	display: block;
	background: var(--tertiary-color);
}

main {
	min-height: 100vh;
	height: fit-content;
}

.leftside {
	width: 30%;
}

.rightside {
	width: 30%;
}

.hero-left {
	width: 60%;
	margin: auto 20px;
	background: var(--tertiary-color);
	padding: 10px 10px;
	border-radius: 1rem;
	font-size: larger;
}

.hero-right {
	width: 40%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	margin: 30px 0;
}

.hero-btns button {
	margin: 0 10px;
	padding: 10px;
	border-radius: 1rem;
	font-size: large;
	font-weight: 600;
}

.top-row {
	color: white;
	text-align: center;
	width: 150vh;
	margin: 40px auto;
	background: var(--secondary-color);
	border-radius: 10px;
	font-size: x-large;
	padding: 20px;
}

.content-row {
	display: flex;
	justify-content: center;
	align-items: center;
}

section div {
	color: #fff;
	font-size: x-large;
}

.card {
	margin: 10px;
	transition: all 0.5s ease;
}

.card:hover {
	transform: scale(1.04);
	z-index: 2;
}

.card-bottom button {
	color: #fff;
	border-radius: 1rem;
	border: none;
	font-weight: 500;
}

button.online {
	background: #5858ff;
}
button.in-person {
	background: #ff5656;
}
button.hybrid {
	background: #c500c5;
}

.filter-buttons {
	max-height: 40vh;
	background: var(--primary-color);
	padding: 15px;
	padding: 10px;
	text-align: center;
	display: flex;
	justify-content: space-evenly;
	position: absolute;
	z-index: 3;
	width: 90%;
	border-top-left-radius: 2rem;
	border-top-right-radius: 2rem;
}

.filter-buttons p {
	margin: auto 0;
}

.filter-button {
	background: var(--quaternary-color);
	border-radius: 1rem;
	padding: 10px;
	color: #fff;
}

.search-bar {
	width: 35%;
}

.learning-header {
	color: white;
	text-align: center;
	border-bottom: solid var(--primary-color);
	width: 40%;
	margin: 0 auto;
}

.ls-block {
	width: 20vh;
	height: 20vh;
	background: green;
	margin: 15px;
	border-radius: 1rem;
}

table,
th,
td {
	border: 1px solid;
	border-width: 1px !important;
}

.hero {
	height: 100%;
	width: 100%;
}

.intro-img {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
	object-fit: cover;
}

.mask {
	width: 100%;
	height: 100vh;
	position: relative;
}

.mask::after {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: #000;
	opacity: 0.6;
}

.hero-content {
	position: absolute;
	top: 4%;
	left: 25%;
	text-align: center;
	width: 50%;
	height: 14%;
	background: rgb(245, 131, 32, 0.77);
	margin: 0 auto;
	border-radius: 2rem;
	display: flex;
	justify-content: space-between;
	box-shadow: 4px 4px 10px #000;
	color: #fff;
}

.course-content {
	text-align: center;
	width: 90%;
	margin: 20px auto;
	border-radius: 2rem;
	justify-content: space-between;
	box-shadow: 4px 4px 10px #000;
	color: #000;
	height: 82vh;
	overflow: auto;
}

.course-container {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	position: relative;
	top: 100px;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
	border-radius: 10px;
	transform: translateX(20px);
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
	margin-top: 100px;
	margin-bottom: 60px;
	border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.course-thumbnail {
	width: 100%;
	height: 20vh;
	border-top-left-radius: 1rem;
	border-top-right-radius: 1rem;
}

.course-thumbnail svg {
	margin: auto;
	display: block;
	height: 100%;
	width: 35%;
}

.course-thumbnail.safety {
	background: #ffff5e;
}
.course-thumbnail.leadership {
	background: #cf82cf;
}
.course-thumbnail.human-resources {
	background: #a2ffff;
}
.course-thumbnail.continuous-improvement {
	background: #77f777;
}
.course-thumbnail.technical {
	background: #fa6060;
}
.course-thumbnail.technology {
	background: #5757f4;
}

@media screen and (max-width: 500px) {
	.home {
		height: 200vh;
	}
}

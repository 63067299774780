header {
	display: flex; /* header is a flex box */
	flex-wrap: wrap; /* header content will wrap on smaller screens by default */
	padding: 5px 10px; /* header space sizing */
	max-height: 200px; /* max header height */
	width: 100%; /* header takes up entire page*/
	z-index: 2; /* header positioned above content */
	justify-content: space-between; /* put left and right side content on opposite sides*/
	position: fixed;
}

.header-logo img {
	max-width: 200px;
	padding: 10px;
	background: rgb(245, 131, 32, 0.77);
	border-radius: 1rem;
	margin: 10px;
}

.header-right {
	display: flex; /* align heading and buttons horizontally */
	align-items: center;
}

header h2 {
	margin: auto 20px; /* center right side heading vertically */
}

header button {
	margin: 5px; /* spread buttons out*/
	border-radius: 5px; /* round button edges */
	width: 6vh; /* responsive button sizing */
	height: 6vh; /* responsive button sizing */
	filter: brightness(1.6);
}

.header-bg {
	background-color: rgba(70, 69, 69, 0.95);
	transition: 1s;
}

.nav-bar a li:hover {
	color: aliceblue;
	transform: scale(1.1); /* make button pop */
}

.nav-bar {
	display: flex;
	margin: 0 20px 0 0;
	padding: 0;
}

.nav-bar li {
	margin: 0 15px;
	list-style: none;
	color: var(--primary-color);
	font-weight: 600;
	font-size: 28px;
	transition: all 0.5s ease;
}

.nav-bar li.active {
	color: blue;
}

.nav-bar a {
	text-decoration: none;
}
